import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Grid } from '@mui/material';

const HomePage = ({ setEmail }) => {
  const [emailInput, setEmailInput] = useState('');

  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setEmail(emailInput);
    // Here you can perform any additional action like submitting the form or making an API call
    // if the user selects the newsletter button
  };

  const handleShortSurveyClick = () => {
    // Handle the short survey button click, navigate to the short survey page
    // You can use a library like react-router-dom to handle routing in your application
    // For simplicity, we'll just show an alert here
    alert('You selected the 5 min Survey button!');
  };

  const handleLongSurveyClick = () => {
    // Handle the long survey button click, navigate to the long survey page
    // Again, for simplicity, we'll just show an alert here
    alert('You selected the 20 min Survey button!');
  };

  return (
    <Container maxWidth="sm" sx={{ marginTop: '2rem' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography variant="h4" gutterBottom>
              Welcome to our Business
            </Typography>
            <Typography variant="body1" gutterBottom>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla mattis facilisis justo, et volutpat massa semper id.
            </Typography>
            <TextField
              type="text"
              placeholder="Enter your email"
              value={emailInput}
              onChange={handleEmailChange}
              fullWidth
              sx={{ marginBottom: '1rem' }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            <Button type="submit" variant="contained" fullWidth onClick={handleFormSubmit} sx={{ marginBottom: '1rem' }}>
              Newsletter
            </Button>
            <Button type="button" variant="contained" fullWidth onClick={handleShortSurveyClick} sx={{ marginBottom: '1rem' }}>
              5 min Survey
            </Button>
            <Button type="button" variant="contained" fullWidth onClick={handleLongSurveyClick}>
              20 min Survey
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;
